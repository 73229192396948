import TrainCitySelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/train-city-selector/1.1.2/index.vue';
import moment from "moment";

export default {
    data() {
        return {
            fromStation: {},
            toStation: {},
            onlySearch: 0,
            time: moment().locale('zh-cn').format('YYYY-MM-DD'),
            fromStationError: false,
            toStationError: false,
            timeError: false,
            pickerOptions: {
                disabledDate: this.disabledDate
            },

            // selected_entity_formTrainCitySelector: null,
            selected_entity_fromTrainCitySelector: {
                // 'firstLetter': 'B',
                // 'isHotCity': 1,
                // 'jianpin': 'bj',
                // 'sn': 131,
                // 'stationCode': 'BJP',
                // 'stationLevel': 1,
                // 'stationName': '北京',
                // 'stationPinYin': 'beijing',
            },
            selected_entity_toTrainCitySelector: {}
        }
    },
    components: {
        TrainCitySelector
    },
    created() {
    },
    mounted() {
        const __this = this;

        __this.fromStation = {
            "administrativeCode":440100,
            "firstLetter":"G",
            "isHotCity":1,
            "jianpin":"gz",
            "sn":704,
            "stationCityId":null,
            "stationCode":"GZQ",
            "stationLevel":1,
            "stationName":"广州",
            "stationPinYin":"guangzhou"
        };

        __this.toStation = {
            "administrativeCode":110100,
            "firstLetter":"B",
            "isHotCity":1,
            "jianpin":"bj",
            "sn":131,
            "stationCityId":null,
            "stationCode":"BJP",
            "stationLevel":1,
            "stationName":"北京",
            "stationPinYin":"beijing"
        };

        __this.$refs.fromTrainCitySelector.init({
            placeholder_text: '请选择出发城市',
            event_handler: {
                event_handler_blur() {
                    // __this.$refs.toTrainCitySelector.focus();
                },
                event_handler_entity_selected() {
                    __this.$refs.toTrainCitySelector.focus();
                },
            },
        })

    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        fromStation(newVal, oldVal) {
            console.log(newVal)
            if (newVal === null) {
                return
            }
            if (newVal.stationCode !== '') {
                this.fromStationError = false
            }
        },
        toStation(newVal, oldVal) {
            if (newVal === null) {
                return
            }
            if (newVal.stationCode !== '') {
                this.toStationError = false
            }
        },
        selected_entity_fromTrainCitySelector(pNewValue, pOldValue) {
            const __this = this;
            console.log(`pNewValue=`);
            console.log(pNewValue);
            console.log(`pOldValue=`);
            console.log(pOldValue);
        },
    },
    computed: {},
    filters: {},
    methods: {
        disabledDate(time) {
            let month = moment().month()
            let day = 0
            if (month === 0 || month === 1) {
                day = 61
            } else if (month === 6) {
                day = 64
            } else {
                day = 63
            }
            return (time.getTime() < Date.now() - 8.64e7) || (time.getTime() > (Date.now() - 8.64e7 + day * 24 * 60 * 60 * 1000))
        },
        __btn_test_click() {
            const __this = this;
            console.log(__this.selected_entity_formTrainCitySelector);
        },
        cancelTimeError(val) {
            this.timeError = false
        },
        changeCity() {
            console.log('a', this.fromStation, this.toStation)
            let fromStationCode = Object.assign({}, this.toStation)
            let toStationCode = Object.assign({}, this.fromStation)
            console.log(fromStationCode, toStationCode)
            /*if (JSON.stringify(fromStationCode) === '{}' || JSON.stringify(toStationCode) === '{}') {
              return
            }*/
            if (JSON.stringify(fromStationCode) === '{}') {
                fromStationCode.stationCode = ''
            }
            if (JSON.stringify(toStationCode) === '{}') {
                toStationCode.stationCode = ''
            }
            this.fromStation = fromStationCode
            this.toStation = toStationCode
        },
        search() {
            if (JSON.stringify(this.fromStation) === '{}' || this.fromStation === null) {
                this.fromStationError = true
                return
            }
            if (JSON.stringify(this.toStation) === '{}' || this.toStation === null) {
                this.toStationError = true
                return
            }
            if (this.time === '' || this.time === null) {
                this.timeError = true
                return
            } else {
                this.timeError = false
            }
            this.$router.push({
                name: 'distributor-train-front-search',
                query: {
                    fromStationCode: this.fromStation.stationCode,
                    // fromStation: this.fromStation.stationName,
                    toStationCode: this.toStation.stationCode,
                    // toStation: this.toStation.stationName,
                    time: this.time,
                    onlySearch: this.onlySearch
                }
            })
        }
    }
}

/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
